<!--
 * @Description: 团队
 * @Autor: JINbiao
 * @Date: 2021-06-10 16:00:34
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-03-02 20:58:16
-->
<template>
  <SideBar :groups="groups"></SideBar>
</template>

<script>
import SideBar from '@/components/SideBar'
export default {
  components: {
    SideBar
  },

  data() {
    return {
      groups: [
        {
          label: '企业内容',
          list: [
            {
              name: '公司简介',
              path: '/mall/team/introduce'
            },
            {
              name: '企业文化',
              path: '/mall/team/culture'
            },
            {
              name: '发展历程',
              path: '/mall/team/history'
            },
            {
              name: '荣誉资质',
              path: '/mall/team/honor'
            },
            {
              name: '资质图片',
              path: '/mall/team/honorImg'
            }
          ]
        },
        {
          label: '团队展示',
          list: [
            {
              name: '团队列表',
              path: '/mall/team/team-list'
            }
          ]
        },
        {
          label: '合作伙伴',
          list: [
            {
              name: '合作伙伴',
              path: '/mall/team/users'
            }
          ]
        }
      ]
    }
  }
}
</script>
